<template>
<div class="row">
    <div class="col">
        <div
            class="alert alert-light alert-elevate fade show"
            role="alert"
        >
            <div class="alert-icon">
                <i
                    :class="{
                        'la la-check kt-font-success': status == 'success',
                        'la la-warning kt-font-warning': status == 'warning',
                        'la la-times kt-font-danger': status == 'danger',
                    }"
                />
            </div>
            <div
                class="alert-text"
                :class="{
                    'kt-font-bold': status == 'danger'
                }"
            >
                {{ statusMessage }}
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'SetupWizardStepStatus',
    props: {
        status: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        statusMessage() {
            const {status, message} = this;
            if (message) {
                return message;
            }
            if (status == 'danger') {
                return message || 'This step has not been completed';
            }
            return message || 'This step has been completed';
        }
    }
};
</script>
