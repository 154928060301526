var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.termStartCutoff
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _vm.isSetupWizard && _vm.step
              ? _c("SetupWizardStepStatus", {
                  attrs: { status: _vm.step.status, message: _vm.step.message },
                })
              : _vm._e(),
            _c("div", { staticClass: "kt-portlet" }, [
              _c("div", { staticClass: "kt-portlet__head" }, [
                _c("div", { staticClass: "kt-portlet__head-label" }, [
                  _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _vm._v(" " + _vm._s(_vm.user.school.name) + " Settings "),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                  _c(
                    "div",
                    { staticClass: "kt-portlet__head-actions" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-label-primary btn-sm",
                          attrs: { to: { name: "SchoolTerms" } },
                        },
                        [_vm._v(" Manage School Terms ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c("div", { staticClass: "form-group validated row" }, [
                  _c("div", { staticClass: "col-lg-6 pb-3" }, [
                    _c("label", [_vm._v("Term Start Date:")]),
                    _c(
                      "div",
                      { staticClass: "input-group date" },
                      [
                        _c("b-form-datepicker", {
                          staticClass: "form-control kt-input",
                          attrs: {
                            dropbottom: "",
                            placeholder: "Select start date",
                          },
                          model: {
                            value: _vm.termStart,
                            callback: function ($$v) {
                              _vm.termStart = $$v
                            },
                            expression: "termStart",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.isValidTermStart
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            " Please enter a date between " +
                              _vm._s(_vm.termStartCutoff) +
                              " and " +
                              _vm._s(_vm.termEndCutoff) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "col-lg-6 pb-3" }, [
                    _c("label", [_vm._v("Term End Date:")]),
                    _c(
                      "div",
                      { staticClass: "input-group date" },
                      [
                        _c("b-form-datepicker", {
                          staticClass: "form-control kt-input",
                          attrs: {
                            dropbottom: "",
                            placeholder: "Select end date",
                          },
                          model: {
                            value: _vm.termEnd,
                            callback: function ($$v) {
                              _vm.termEnd = $$v
                            },
                            expression: "termEnd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "kt-section kt-section--first mt-4" },
                  [
                    _c("h3", { staticClass: "kt-section__title mb-5" }, [
                      _vm._v(" School Term Finalization Settings "),
                    ]),
                    _c("div", { staticClass: "form-group row mb-1" }, [
                      _c("label", { staticClass: "col-3 col-form-label" }, [
                        _vm._v("Finalized"),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c("span", { staticClass: "kt-switch kt-switch--sm" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.finalized,
                                  expression: "finalized",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.finalized)
                                  ? _vm._i(_vm.finalized, null) > -1
                                  : _vm.finalized,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.finalized,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.finalized = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.finalized = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.finalized = $$c
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                      ]),
                      _c("label", { staticClass: "col-3 col-form-label" }, [
                        _vm._v("Default Term"),
                      ]),
                      _c("div", { staticClass: "col-3" }, [
                        _c("span", { staticClass: "kt-switch kt-switch--sm" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.current,
                                  expression: "current",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.current)
                                  ? _vm._i(_vm.current, null) > -1
                                  : _vm.current,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.current,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.current = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.current = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.current = $$c
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group row mt-5" }, [
                      _c("div", { staticClass: "col-3 offset-9" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-brand pull-right",
                            class: {
                              "kt-spinner kt-spinner--sm kt-spinner--light":
                                _vm.saving,
                            },
                            attrs: {
                              title: _vm.isValidForm
                                ? ""
                                : "Please enter valid term start and term end dates to continue",
                              disabled: !_vm.isValidForm,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.editSchoolTerm.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" Save Term Settings ")]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _vm.isSuperAdmin
                    ? _c("SchoolSubscriptions", {
                        attrs: {
                          "external-school-id": _vm.school
                            ? _vm.school.externalSchoolId
                            : null,
                          "school-year": _vm.schoolYear,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }