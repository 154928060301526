var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        {
          staticClass: "alert alert-light alert-elevate fade show",
          attrs: { role: "alert" },
        },
        [
          _c("div", { staticClass: "alert-icon" }, [
            _c("i", {
              class: {
                "la la-check kt-font-success": _vm.status == "success",
                "la la-warning kt-font-warning": _vm.status == "warning",
                "la la-times kt-font-danger": _vm.status == "danger",
              },
            }),
          ]),
          _c(
            "div",
            {
              staticClass: "alert-text",
              class: {
                "kt-font-bold": _vm.status == "danger",
              },
            },
            [_vm._v(" " + _vm._s(_vm.statusMessage) + " ")]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }