<template>
<div v-if="termStartCutoff">
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <SetupWizardStepStatus
            v-if="isSetupWizard && step"
            :status="step.status"
            :message="step.message"
        />

        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        {{ user.school.name }} Settings
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div
                        class="kt-portlet__head-actions"
                    >
                        <router-link
                            :to="{ name: 'SchoolTerms' }"
                            class="btn btn-label-primary btn-sm"
                        >
                            Manage School Terms
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="form-group validated row">
                    <div class="col-lg-6 pb-3">
                        <label>Term Start Date:</label>

                        <div class="input-group date">
                            <b-form-datepicker
                                v-model="termStart"
                                dropbottom
                                class="form-control kt-input"
                                placeholder="Select start date"
                            />
                        </div>

                        <div
                            v-if="!isValidTermStart"
                            class="invalid-feedback"
                        >
                            Please enter a date between {{ termStartCutoff }} and {{ termEndCutoff }}
                        </div>
                    </div>
                    <div class="col-lg-6 pb-3">
                        <label>Term End Date:</label>

                        <div class="input-group date">
                            <b-form-datepicker
                                v-model="termEnd"
                                dropbottom
                                class="form-control kt-input"
                                placeholder="Select end date"
                            />
                        </div>
                    </div>
                </div>
                <div class="kt-section kt-section--first mt-4">
                    <h3 class="kt-section__title mb-5">
                        School Term Finalization Settings
                    </h3>
                    <div class="form-group row mb-1">
                        <label class="col-3 col-form-label">Finalized</label>
                        <div class="col-3">
                            <span class="kt-switch kt-switch--sm">
                                <label>
                                    <input
                                        v-model="finalized"
                                        type="checkbox"
                                    >
                                    <span />
                                </label>
                            </span>
                        </div>
                        <label class="col-3 col-form-label">Default Term</label>
                        <div class="col-3">
                            <span class="kt-switch kt-switch--sm">
                                <label>
                                    <input
                                        v-model="current"
                                        type="checkbox"
                                    >
                                    <span />
                                </label>
                            </span>
                        </div>
                    </div>

                    <div class="form-group row mt-5">
                        <div class="col-3 offset-9">
                            <button
                                class="btn btn-brand pull-right"
                                :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                                :title="isValidForm ? '' : 'Please enter valid term start and term end dates to continue'"
                                :disabled="!isValidForm"
                                @click.stop.prevent="editSchoolTerm"
                            >
                                Save Term Settings
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <SchoolSubscriptions
                    v-if="isSuperAdmin"
                    :external-school-id="school ? school.externalSchoolId : null"
                    :school-year="schoolYear"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import async from 'async';
import moment from 'moment';
import SetupWizardStepStatus from '../components/SetupWizardStepStatus.vue';
import SchoolSubscriptions from './super/SchoolSubscriptions.vue';
import * as network from '../network';
import Types from '../store/Types';

export default {
    name: 'SchoolTermSettings',
    components: {
        SetupWizardStepStatus,
        SchoolSubscriptions,
    },
    data() {
        return {
            termStart: null,
            termEnd: null,
            finalized: false,
            current: false,
            saving: false,
            termStartCutoff: '',
            termEndCutoff: '',
            communicationPlan: 'trial',
            communicationEnabled: true,
            anecdotalsEnabled: true,
            gradingEnabled: true,
            encryptionEnabled: true,
            reportCardsEnabled: true,
            reportingEnabled: true,

            adminSettings: {
                adminLocked: false,
                communicationEnabled: true,
                anecdotalsEnabled: true,
                gradingEnabled: true,
                encryptionEnabled: true,
                reportCardsEnabled: true,
                reportingEnabled: true,
                communicationPlan: 'trial',
                purchaseOrderNumber: '',
                emailQuota: 0,
                smsQuota: 0,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            schoolTerms: (state) => state.user.schoolTerms,
            dbSchoolTerms: (state) => state.database.schoolTerms,
            isDistrictManaged: (state) => Boolean(state.user.school.externalDistrictId.endsWith('-Google')),
        }),
        school() {
            const { schoolTermId, schoolId } = this.user.school;
            return this.$store.state.database.schools.find((s) => s.schoolId == schoolId) || null;
        },
        googleSetting() {
            return this.$store.state.wizard.bypassWizardGoogleDomain;
        },
        enableGrading: {
            get() {
                return this.$store.state.wizard.bypassWizardGradingEnabled == 'true';
            },
            set(enableGrading) {
                this.gradingEnabled = false;
                if (!this.isSetupWizard) return;
                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: 'setup-grading',
                    value: enableGrading ? 'true' : '',
                });
                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
                this.gradingEnabled = enableGrading;
            },
        },
        enableCommunicationValue: {
            get() {
                return this.$store.state.wizard.bypassWizardCommunication || 'trial';
            },
            set(value) {
                if (!this.isSetupWizard) return;
                this.communicationPlan = value;
                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: this.step.routeName,
                    value,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        enableCommunication: {
            get() {
                const { bypassWizardCommunication } = this.$store.state.wizard;
                if (bypassWizardCommunication == '' || bypassWizardCommunication === 'disabled') {
                    return false;
                }
                return true;
            },
            set(enableCommunication) {
                if (!this.isSetupWizard) return;
                const value = enableCommunication ? 'trial' : 'disabled';
                this.communicationEnabled = value;
                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: 'setup-communication',
                    value,
                });
                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
        },
        enableBadges: {
            get() {
                const { bypassWizardBadges } = this.$store.state.wizard;
                if (bypassWizardBadges == 'true') return true;
                return false;
            },
            set(enabled) {
                const value = enabled ? 'true' : '';
                this.anecdotalsEnabled = value;
                this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                    routeName: 'setup-anecdotals',
                    value,
                });

                this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
            },
            enableEncryption: {
                get() {
                    const { bypassWizardEncryption } = this.$store.state.wizard;
                    if (bypassWizardEncryption == 'true') return true;
                    return false;
                },
                set(enabled) {
                    const value = enabled ? 'true' : '';
                    this.encryptionEnabled = value;
                    this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, {
                        routeName: this.step.routeName,
                        value,
                    });

                    this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);
                },
            },
        },
        isSetupWizard() {
            return this.$route.name == 'SetupWizardRoute';
        },
        step() {
            return this.$store.state.wizard.steps.find((s) => s.routeName === 'SchoolTerms');
        },
        isValidForm() {
            return this.isValidTermEnd && this.isValidTermStart;
        },
        isValidTermEnd() {
            const termEnd = moment(this.termEnd, 'YYYY-MM-DD');
            return termEnd.isBetween(this.termStartCutoff, this.termEndCutoff);
        },
        isValidTermStart() {
            const termStart = moment(this.termStart, 'YYYY-MM-DD');
            return termStart.isBetween(this.termStartCutoff, this.termEndCutoff);
        },
        isSuperAdmin() {
            return this.$store.state.user.isSuperAdmin
        },
        isLocked() {
            if (this.$store.state.user.school.finalized) return true;
            if (!this.subscription) return false;
            return this.subscription.adminLocked;
        },
        subscription() {
            const { school, user } = this;
            if (!school) return null;
            const { schoolYear } = user.school;
            return this.school.subscriptions.find((s) => s.schoolYear == schoolYear) || null;
        },
        schoolYear() {
            return this.$store.state.user.school.schoolYear;
        }
    },
    mounted() {
        const { subscription, schoolTerms } = this;
        const { schoolTermId, finalized } = this.user.school;
        const term = schoolTerms.find((t) => t.schoolTermId == schoolTermId);
        if (!term) return;
        // the slice removes the timestamp from the date string
        this.termEnd = term.termEnd?.slice(0, 10) || '';
        this.termStart = term.termStart?.slice(0, 10) || '';

        this.termStartCutoff = `${this.user.school.schoolYear}-08-30`;
        this.termEndCutoff = `${parseInt(this.user.school.schoolYear, 10) + 1}-09-01`;

        this.finalized = term.finalized;
        this.current = term.current;
        const schoolYearSubscription = subscription;
        const schoolTermSubscription = term ? term.subscription : null;

        if (schoolYearSubscription) {

            if (!finalized) {
                //pick from the wizard settings
                this.communicationEnabled = Boolean(this.$store.state.wizard.bypassWizardCommunication);
                this.anecdotalsEnabled = this.$store.state.wizard.bypassWizardBadges;
                this.gradingEnabled = this.$store.state.wizard.bypassWizardGradingEnabled;
                this.encryptionEnabled =  this.$store.state.wizard.bypassWizardEncryption;
                this.reportCardsEnabled = this.$store.state.wizard.bypassWizardReportCardsEnabled;
                this.communicationPlan = this.$store.state.wizard.bypassWizardCommunication;
            } else {
                //pick from the schoolTermSubscription settings
                this.communicationEnabled = schoolTermSubscription.communicationEnabled;
                this.anecdotalsEnabled = schoolTermSubscription.anecdotalsEnabled
                this.gradingEnabled = schoolTermSubscription.gradingEnabled;
                this.encryptionEnabled = schoolTermSubscription.encryptionEnabled;
                this.reportCardsEnabled = schoolTermSubscription.reportCardsEnabled;
                this.communicationPlan = schoolTermSubscription.communicationPlan;
            }

            if (schoolYearSubscription.adminLocked) {
                // force the user to use the subscription settings
                this.communicationEnabled = schoolYearSubscription.communicationEnabled;
                this.anecdotalsEnabled = schoolYearSubscription.anecdotalsEnabled;
                this.gradingEnabled = schoolYearSubscription.gradingEnabled;
                this.encryptionEnabled =  schoolYearSubscription.encryptionEnabled;
                this.reportCardsEnabled = schoolYearSubscription.reportCardsEnabled;
                this.communicationPlan = schoolYearSubscription.communicationPlan;
            }

            this.adminSettings.communicationEnabled = this.communicationEnabled;
            this.adminSettings.anecdotalsEnabled = this.anecdotalsEnabled;
            this.adminSettings.gradingEnabled = this.gradingEnabled;
            this.adminSettings.encryptionEnabled = this.encryptionEnabled;
            this.adminSettings.reportCardsEnabled = this.reportCardsEnabled;
            this.adminSettings.communicationPlan = this.communicationPlan;
        }

        this.adminSettings = {
            adminLocked: schoolYearSubscription.adminLocked,
            communicationEnabled: this.communicationEnabled,
            anecdotalsEnabled: this.anecdotalsEnabled,
            gradingEnabled: this.gradingEnabled,
            encryptionEnabled: this.encryptionEnabled,
            reportCardsEnabled: this.reportCardsEnabled,
            reportingEnabled: this.reportingEnabled,
            communicationPlan: this.communicationPlan,
            purchaseOrderNumber: '',
            emailQuota: schoolYearSubscription.emailQuota,
            smsQuota: schoolYearSubscription.smsQuota,
        };
    },
    methods: {
        editSchoolTerm() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { schoolId, schoolTermId } = v.user.school;

            // although you are sending these settings, the server will only save products
            // if the user has permission (eg !subscription.adminLocked) then the term settings save normally

            const schoolTermSettings = {
                externalSchoolId: v.user.school.externalSchoolId,
                schoolYear: v.user.school.schoolYear,
                schoolTerm: v.user.school.schoolTerm,
                termStart: v.termStart,
                termEnd: v.termEnd,
                current: v.current,
                finalized: v.finalized,
                deleted: false,

                // these are only saved if subscription?.adminLocked is false
                adminLocked: v.adminSettings.adminLocked,
                communicationEnabled: v.finalized ? true : v.enableCommunication,
                anecdotalsEnabled: v.finalized ? true : v.enableBadges,
                gradingEnabled: v.finalized ? true : v.enableGrading,
                encryptionEnabled: true,
                reportCardsEnabled: true,
                reportingEnabled: true,
                googleSetting: v.googleSetting || v.$store.state.user.school.googleSetting,
                communicationPlan: v.communicationPlan || 'trial',
            };

            const params = {
                url: { schoolId, schoolTermId, action: 'edit' },
                body: { schoolTermSettings },
            };

            network.schoolTerms.createTerm(params, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                const { isSuperAdmin } = v;
                if (isSuperAdmin) {
                    window.location.reload();
                } else {
                    window.location.href = '/home';
                }
            });
        },
    },
};
</script>
